


































// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Slide } from 'vue-burger-menu'

// Store
import { userNamespace } from '@store/user'

// Interfaces
import { ISelect } from '@/interfaces/Interface'
import { IUser, IProject } from '@store/user/Interface'
import { User } from '@/store/users/interfaces'

@Component({
  name: 'Footer',

  components: {
    'v-form-support': () => import('@/components/forms/FormSupport.vue'),
  },
})
export default class FooterComponent extends Vue {
  @Prop(Object) private user!: User

  private showFormSupport = false
}
